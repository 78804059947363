.box
{
    margin: 20px;
    padding:5px ; 
    height:50%;
    width:50%;
    position: relative;
    box-shadow: 0 .5rem 1.5rem #2b7a78;

}
.boxMainPage{
    margin: 20px;
    padding:10px ; 
    height:50%;
    width:50%;
    font-size: larger;
    position: relative;
    box-shadow: 0 .5rem 1.5rem #2b7a78;
}
.boxMapSec{
    margin: 20px;
    padding:10px ; 
    height: 35%;
    max-width: 25vh;
    width:40%;
    position: relative;
    box-shadow: 0 .5rem .5rem #2b7a78;
}
.box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8,.box9,.box10{
    background-color: #ffffff;
    border-radius: 10px,10px,10px,10px;
    overflow: hidden;
    height:200px;
    max-height: 25vh;
    -webkit-box-shadow:0px,12px,18px,-6px rgba(0,0,0,0.3);
    box-shadow:0px,12px,18px,-6px rgba(0,0,0,0.3);
}
.boximg{
    padding: 1px;
    width:auto;
    height: 200px;
    max-height: 400px;
}
.grid{
    margin: auto;
    opacity: 0.9;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.grid>div{
    flex-basis: (25%-40px);
}