.App {
  text-align: center;
}
.btn1{
  font-size: 14px !important;
  border-radius: 3px !important;
  border-color: #3b3c3d !important;
  padding: 0 30px !important;
  color: black;
  background: transparent;
}
.btn1-disabled{
  font-size: 14px !important;
  border-radius: 3px !important;
  border-color: #3b3c3d !important;
  padding: 0 30px !important;
  background-color: gray;
  color: #feffff !important;
}
.btn1-signout{
  font-size: 14px !important;
  border-radius: 3px !important;
  border-color: #0a1817 !important;
  background-color: #0a1817;
  color: #feffff;
  padding: 5px 30px !important;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.btn1:hover{
background: #0f1923 !important;
box-shadow: 3px 3px  5px #e0f9fd;
color: #feffff !important;
-webkit-transition: background-color 250ms ease-in;
-moz-transition: background-color 250ms ease-in;
-o-transition: background-color 250ms ease-in;
transition: background-color 250ms ease-in;
}
.btn1-disabled:hover{
  cursor: not-allowed;
  /* background: gray !important; */
  /* box-shadow: 3px 3px  5px #3f4c4e; */
  /* color: #feffff !important; */
  /* -webkit-transition: background-color 250ms ease-in;
  -moz-transition: background-color 250ms ease-in;
  -o-transition: background-color 250ms ease-in;
  transition: background-color 250ms ease-in; */
  }
.btn1-signout:hover{
  cursor: pointer;
  background:  #a6c5f8  !important;
  box-shadow: 3px 3px  5px #273c3f;
  border-color: #0a1817;
  color: #0f1923 !important;
  -webkit-transition: background-color 250ms ease-in;
  -moz-transition: background-color 250ms ease-in;
  -o-transition: background-color 250ms ease-in;
  transition: background-color 250ms ease-in;
  }
  .main-container {
    max-width: 100%;
    padding: 0 1.5rem 0 1.5rem;
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    /* padding-bottom: 100px; height of your footer */
   }
 .flexbox-container {
  display: flex;
  flex-direction: column;
}
.head{
  display: flex;
  /* background-color: #29292c; */
  background: linear-gradient(to right, #0a1817, #4e5b62, #82a0ad);
  color: #feffff;
  padding: 0.8rem 2rem 0 2rem;
}
.footer {
  position: relative;
  bottom: 0;
  
  width: 100% !important;
  color: #feffff;
  /* margin-right: 2rem !important; */
  background-color: #0a1817;
 }

 h1{
   color: white !important;
 }