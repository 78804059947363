
.login {
    width: 100%;
    min-height: 75vh;
    padding: 20px 0;
    justify-content: center;
    display: flex;
  }
  
  .login .loginContainer {
      padding: 60px;
      margin: auto;
      width: 100%;
      justify-content: center;
      max-width: 520px;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: white;
      box-shadow: 0 .5rem 1.5rem #2b7a78;
    }
    .login .loginContainer .errorMsg {
      color: red;
    }
    .login .loginContainer label {
      color: black;
      margin: 10px 0;
      display: block;
      line-height: 1;
    }
  
   .header
   {
      justify-self: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      
   }
    
    .login .loginContainer input{
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 1px solid black;
      width: 100%;
     
      border-color: black!important;
      padding: 6px;
      background: rgb(255, 255, 255);
      color: black;
    }
    .login .loginContainer Select{
      border: 0;
      outline: 0;

      background: transparent;
      border-bottom: 1px solid black!important;
      width: 100%;
      border-color: black!important;
      padding: 6px;
      background: rgb(255, 255, 255);
      color: black;
    }
  .textalign{
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
  }